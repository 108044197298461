import {
    CAISO_PRE_SCREEN_CLUSTER_15_SCOPE_TYPE,
    DUKE_DISIS_2024_PHASE_1_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE,
    ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
    MISO_2022_MTEP_SCOPE_TYPE,
    MISO_2022_REALISTIC_SCOPE_TYPE,
    MISO_2022_SCOPE_TYPE,
    NYISO_2029_SCOPE_TYPE,
    NYISO_CLASS_YEAR_2029_SCOPE_TYPE,
    PJM_2028_LOAD_SCOPE,
    PJM_ALL_QUEUE_SCOPE,
    PJM_ISA_ONLY_SCOPE_TYPE,
    ScopeType,
    SOCO_2028_SCOPE_TYPE,
    SPP_ALL_GENERATORS_SCOPE_TYPE,
    SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE,
    TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE,
    TVA_2028_WITH_PRIORS_SCOPE_TYPE,
    WECC_ALL_GENERATORS_SCOPE_TYPE,
    WECC_BASE_SCOPE_TYPE,
    WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE
} from "types/scopeType";
import { GeneratorType } from "./generatorType";
import { RegionType } from "./regionType";

export type ScopeConfigurationType = {
    readonly scope: ScopeType;
    readonly label: string;
    readonly disabled: boolean;
};

const NYISO_SCOPES: ScopeConfigurationType[] = [
    {
        scope: NYISO_2029_SCOPE_TYPE,
        label: "Firm Generation Only",
        disabled: false
    },
    {
        scope: NYISO_CLASS_YEAR_2029_SCOPE_TYPE,
        label: "Class Year 23 Included",
        disabled: false
    }
];
const PJM_SCOPES: ScopeConfigurationType[] = [
    {
        scope: PJM_ISA_ONLY_SCOPE_TYPE,
        label: "Firm and Fast Lane generators",
        disabled: false
    },
    {
        scope: PJM_ALL_QUEUE_SCOPE,
        label: "All generators included",
        disabled: false
    }
];
const PJM_LOAD_SCOPES: ScopeConfigurationType[] = [
    {
        scope: PJM_2028_LOAD_SCOPE,
        label: "PJM 2028 Load",
        disabled: false
    }
];
const MISO_SCOPES: ScopeConfigurationType[] = [
    {
        scope: MISO_2022_REALISTIC_SCOPE_TYPE,
        label: "MISO Realistic",
        disabled: false
    },
    {
        scope: MISO_2022_SCOPE_TYPE,
        label: "MISO 2022 phase 1",
        disabled: false
    }
];
const MISO_LOAD_SCOPES: ScopeConfigurationType[] = [
    {
        scope: MISO_2022_MTEP_SCOPE_TYPE,
        label: "MISO 2022 MTEP",
        disabled: false
    }
];
const SPP_SCOPES: ScopeConfigurationType[] = [
    {
        scope: SPP_DISIS_2023_01_PHASE_1_PQ_SCOPE_TYPE,
        label: "DISIS 2023 Phase 1 PQ",
        disabled: false
    },
    {
        scope: SPP_ALL_GENERATORS_SCOPE_TYPE,
        label: "All generators included",
        disabled: false
    }
];

const WECC_SCOPES: ScopeConfigurationType[] = [
    {
        scope: WECC_BASE_SCOPE_TYPE,
        label: "WECC base models",
        disabled: false
    },
    {
        scope: WECC_LATE_STAGE_GENERATORS_SCOPE_TYPE,
        label: "Late-stage generators included",
        disabled: false
    },
    {
        scope: WECC_ALL_GENERATORS_SCOPE_TYPE,
        label: "All queued generators included",
        disabled: false
    }
];
const TVA_SCOPES: ScopeConfigurationType[] = [
    {
        scope: TVA_2028_WITH_PRIORS_SCOPE_TYPE,
        label: "With priors - 2028 case",
        disabled: false
    },
    {
        scope: TVA_2028_WITHOUT_PRIORS_SCOPE_TYPE,
        label: "Without priors - 2028 case",
        disabled: false
    }
];
const ISONE_SCOPES: ScopeConfigurationType[] = [
    {
        scope: ISONE_2026_SUMMER_PEAK_SCOPE_TYPE,
        label: "Firm and Fast Track generators included",
        disabled: false
    },
    {
        scope: ISONE_2026_SUMMER_PEAK_ALL_QUEUED_GENERATORS_SCOPE_TYPE,
        label: "All queued generators included",
        disabled: false
    }
];
const SOCO_SCOPES: ScopeConfigurationType[] = [
    {
        scope: SOCO_2028_SCOPE_TYPE,
        label: "2028 case with all generators",
        disabled: false
    }
];
const DUKE_SCOPES: ScopeConfigurationType[] = [
    {
        scope: DUKE_DISIS_2024_PHASE_1_SCOPE_TYPE,
        label: "Duke DISIS 2023 Phase 2",
        disabled: false
    }
];
const CAISO_SCOPES: ScopeConfigurationType[] = [
    {
        scope: CAISO_PRE_SCREEN_CLUSTER_15_SCOPE_TYPE,
        label: "Screening",
        disabled: false
    }
];

export const regionToScopeConfigurationType: {
    [key in RegionType]: {
        readonly [key in GeneratorType]: ReadonlyArray<ScopeConfigurationType>;
    };
} = {
    NYISO: {
        solar: NYISO_SCOPES,
        wind: [],
        storage: NYISO_SCOPES,
        load: []
    },
    PJM: {
        solar: PJM_SCOPES,
        wind: PJM_SCOPES,
        storage: PJM_SCOPES,
        load: PJM_LOAD_SCOPES
    },
    MISO: {
        solar: MISO_SCOPES,
        wind: MISO_SCOPES,
        storage: MISO_SCOPES,
        load: MISO_LOAD_SCOPES
    },
    CAISO: {
        solar: CAISO_SCOPES,
        wind: CAISO_SCOPES,
        storage: CAISO_SCOPES,
        load: []
    },
    SPP: {
        solar: SPP_SCOPES,
        wind: SPP_SCOPES,
        storage: [],
        load: []
    },
    ERCOT: {
        solar: [],
        wind: [],
        storage: [],
        load: []
    },
    WECC: {
        solar: WECC_SCOPES,
        wind: [],
        storage: WECC_SCOPES,
        load: []
    },
    TVA: {
        solar: TVA_SCOPES,
        wind: [],
        storage: [],
        load: []
    },
    ISONE: {
        solar: ISONE_SCOPES,
        wind: [],
        storage: ISONE_SCOPES,
        load: []
    },
    SOCO: {
        solar: SOCO_SCOPES,
        wind: [],
        storage: [],
        load: []
    },
    DUKE: {
        solar: DUKE_SCOPES,
        wind: [],
        storage: DUKE_SCOPES,
        load: []
    }
};

export const getScopeConfigForScope = (
    scope: ScopeType,
    scopeConfigs: ScopeConfigurationType[]
): ScopeConfigurationType | undefined => {
    return scopeConfigs.find((scopeConfig) => scopeConfig.scope === scope);
};

export const getScopeConfigForRegionGeneratorAndScope = (
    region: RegionType,
    generatorType: GeneratorType,
    scope: ScopeType
): ScopeConfigurationType | undefined => {
    const scopeConfigs = regionToScopeConfigurationType[region][generatorType];
    return getScopeConfigForScope(scope, [...scopeConfigs]);
};

export const hasMultipleScopeConfigurations = (
    region: RegionType,
    generatorType: GeneratorType
): boolean => {
    const configs = regionToScopeConfigurationType[region][generatorType];
    return configs.length > 1;
};
