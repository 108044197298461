import { GetQueueProjectsQuery, Queue_Projects } from "generated/graphql";
import { convertMaybeToUndefined } from "graphql/helpers/queryHelpers";

export type QueueProject = {
    readonly subRegion: string;
    readonly cluster: string | undefined;
    readonly projectNumber: string;
    readonly busId: string | undefined;
    readonly facilityId: string | undefined;
    readonly poiDescription: string;
    readonly erisCapacityMw: number;
    readonly nrisCapacityMw: number | undefined;
    readonly fuelType: string;
    readonly status: string | undefined;
    readonly included: boolean;
};

type HasuraQueueProject = Readonly<
    Pick<
        Queue_Projects,
        | "sub_region"
        | "cluster"
        | "project_number"
        | "bus_id"
        | "facility_id"
        | "poi_description"
        | "eris_capacity_mw"
        | "nris_capacity_mw"
        | "fuel_type"
        | "status"
        | "included"
    >
>;
const convertHasuraQueueProjectToNiraQueueProject = (
    hasuraQueueProject: HasuraQueueProject
): QueueProject => {
    return {
        subRegion: hasuraQueueProject.sub_region,
        cluster: convertMaybeToUndefined(hasuraQueueProject.cluster),
        projectNumber: hasuraQueueProject.project_number,
        busId: convertMaybeToUndefined(hasuraQueueProject.bus_id),
        facilityId: convertMaybeToUndefined(hasuraQueueProject.facility_id),
        poiDescription: hasuraQueueProject.poi_description,
        erisCapacityMw: hasuraQueueProject.eris_capacity_mw,
        nrisCapacityMw: convertMaybeToUndefined(
            hasuraQueueProject.nris_capacity_mw
        ),
        fuelType: hasuraQueueProject.fuel_type,
        status: convertMaybeToUndefined(hasuraQueueProject.status),
        included: hasuraQueueProject.included
    };
};

export const convertHasuraQueueProjectsToNiraQueueProjects = (
    data: GetQueueProjectsQuery
): ReadonlyArray<QueueProject> => {
    return data.queue_projects.map(convertHasuraQueueProjectToNiraQueueProject);
};
