import {
    Scenario_Statuses_Enum,
    SubscribeToInQueueScenariosSubscription
} from "generated/graphql";
import { isEqual } from "lodash";
import { Loading } from "types/loadingType";
import { convertToRegionType } from "types/regionType";
import {
    convertToStudyCycleType,
    convertToStudyGroupType,
    convertToStudyPhaseType,
    InQueueCluster
} from "./clusterType";
import { TrackedProject } from "./projectType";

// Re-exports the Hasura-generated status types under a different name
// This enables us to change how this is structured in the future if we
// want to decouple from the Hasura typings.
export type ScenarioStatus = Scenario_Statuses_Enum;
export const ScenarioStatus = Scenario_Statuses_Enum;

export type ScenarioType = "SHARED" | "CUSTOM";
export type ScenarioStage = "PREVIEW" | "DECISION_POINT";

export type ProjectScenarioMapping = {
    [projectId: string]: {
        [phase: string]: ScenarioMetadata[];
    };
};
export interface InputData {
    concise: string;
    description: string;
}
export interface ScenarioMetadata {
    type: ScenarioType;
    stage: ScenarioStage;
    scenarioId: string;
    title: string;

    cluster: InQueueCluster;

    assumptions: Record<string, MisoPercentageReduction>;

    status: ScenarioStatus;
    inputDataUsed?: InputData;
    timeMetadata: ScenarioTimeMetadata;

    parentProjectId?: string;

    costAssumptions: CostAssumption;
}

export enum CostAssumptionType {
    CONSERVATIVE = "CONSERVATIVE",
    CUSTOM = "CUSTOM"
}

export type CustomCostAssumption = {
    type: CostAssumptionType.CUSTOM;
    reconductorLoadingLimit: number;
    rebuildLoadingLimit: number;
};

export type ConservativeCostAssumption = {
    type: CostAssumptionType.CONSERVATIVE;
};

export type CostAssumption = CustomCostAssumption | ConservativeCostAssumption;

export enum ProjectSizeAdjustmentType {
    PERCENTAGE_REDUCTION = "PERCENTAGE_REDUCTION"
}

export interface ProjectSizeAssumptions {
    type: ProjectSizeAdjustmentType;
}

export interface MisoPercentageReduction extends ProjectSizeAssumptions {
    type: ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION;
    eris: number;
    nris: number;
}

export interface ScenarioTimeMetadata {
    createdAt: Date;
    updatedAt: Date;
    runSubmittedAt?: Date;
    runStartedAt?: Date;
    runCompletedAt?: Date;

    latestDate: Date;
}

export type PercentageReductionType =
    typeof ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION;
export type HasuraProjectSizeAdjustment = {
    type: PercentageReductionType;
    eris: number;
    nris: number;
};
export type HasuraProjectSizeAssumptions = {
    [projectId: string]: HasuraProjectSizeAdjustment;
};

type ScenarioMetadataWithMaybeProjectIds = ScenarioMetadata & {
    projectIds?: string[];
};
export const convertHasuraInQueueScenariosToProjectScenarioMapping = (
    hasuraData: SubscribeToInQueueScenariosSubscription,
    projects: Loading<TrackedProject[]>
): Loading<ProjectScenarioMapping> => {
    if (projects === "loading") {
        return "loading";
    }

    // Preprocess results into ScenarioMetadata
    const scenarios: ScenarioMetadataWithMaybeProjectIds[] =
        hasuraData.scenarios.map((s) => {
            let maybeProjectIds;
            if (s.scenario_type === "CUSTOM") {
                // Use parent project ID if present, otherwise use tracked projects
                maybeProjectIds = s.parent_project_id
                    ? [s.parent_project_id]
                    : s.in_queue_subscription?.tracked_projects.map(
                          (p) => p.project_id
                      );
            }
            return {
                scenarioId: s.scenario_id,
                type: s.scenario_type as ScenarioType,
                stage: s.scenario_stage as ScenarioStage,
                title: s.title,
                assumptions: s.project_size_assumptions,

                cluster: {
                    // Technically, we could get into a weird state where we have a scenario in the DB that the FE
                    // doesnt understand yet, in which case this will error here. I don't think this is worth fixing
                    // yet, but if we run into any issues, we should look into this.
                    region: convertToRegionType(s.region),
                    studyCycle: convertToStudyCycleType(s.study_cycle),
                    studyGroup: convertToStudyGroupType(s.study_group),
                    studyPhase: convertToStudyPhaseType(s.study_phase)
                },

                inputDataUsed: s.input_data_used,
                status: s.status,
                timeMetadata: convertHasuraScenarioToScenarioRunMetadata(s),

                projectIds: maybeProjectIds,
                parentProjectId: s.parent_project_id ?? undefined,

                costAssumptions: s.cost_assumptions
            };
        });

    // Iterate over projects and find matching scenarios
    const result: ProjectScenarioMapping = {};
    for (const project of projects) {
        const projectId = project.projectId;

        const filteredScenarios = scenarios.filter((s) =>
            isEqual(project.cluster, s.cluster)
        );

        const relevantSharedScenarios = filteredScenarios.filter(
            (s) => s.type === "SHARED"
        );
        const relevantCustomScenarios = filteredScenarios.filter(
            (s) => s.type === "CUSTOM" && s.projectIds?.includes(projectId)
        );

        const projectScenarios = [
            ...relevantSharedScenarios,
            ...relevantCustomScenarios
        ];

        result[projectId] = {
            ...result[projectId],
            [project.cluster.studyPhase]: projectScenarios
        };
    }

    return result;
};

const convertHasuraScenarioToScenarioRunMetadata = (input: {
    created_at: string;
    updated_at: string;
    run_submitted_at?: string;
    run_started_at?: string;
    run_completed_at?: string;
}): ScenarioTimeMetadata => {
    const createdAt = new Date(input.created_at);
    const runSubmittedAt = input.run_submitted_at
        ? new Date(input.run_submitted_at)
        : undefined;
    const runStartedAt = input.run_started_at
        ? new Date(input.run_started_at)
        : undefined;
    const runCompletedAt = input.run_completed_at
        ? new Date(input.run_completed_at)
        : undefined;
    return {
        createdAt,
        updatedAt: new Date(input.updated_at),
        runSubmittedAt,
        runStartedAt,
        runCompletedAt,
        latestDate:
            runCompletedAt ?? runStartedAt ?? runSubmittedAt ?? createdAt
    };
};

export enum ScenarioAssumptionsCSVFields {
    project_id = "project_id",
    eris_percentage_reduction = "eris_percentage_reduction",
    nris_percentage_reduction = "nris_percentage_reduction"
}

export interface ScenarioAssumptionsCSV {
    [ScenarioAssumptionsCSVFields.project_id]: string;
    [ScenarioAssumptionsCSVFields.eris_percentage_reduction]: number;
    [ScenarioAssumptionsCSVFields.nris_percentage_reduction]: number;
}
