import {
    Button,
    Intent,
    OverlayToaster,
    Position,
    Tooltip
} from "@blueprintjs/core";
import { segmentTrackDataFreshnessDownloadQueueProjects } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useGetQueueProjectsLazyQuery } from "generated/graphql";
import {
    convertHasuraQueueProjectsToNiraQueueProjects,
    QueueProject
} from "types/queueProjectsType";
import { getScopeConfigForRegionGeneratorAndScope } from "types/scopeConfigurationType";
import { downloadJSONToCSV } from "utils/csv";
import {
    getQueueProjectsExportColumnsForRegion,
    QueueProjectsExportColumn
} from "./queueProjectsExportColumn";

const DownloadQueueProjectsToaster = OverlayToaster.create({
    position: Position.TOP
});

const DownloadQueueProjectsButton: React.FC = () => {
    const { region, scopeView, generator } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: {
            busFilters: { scope }
        }
    } = useMapViewConfiguration();

    const [getQueueProjects] = useGetQueueProjectsLazyQuery();

    // this should never actually be undefined since otherwise the user wouldn't be able to select it
    const scopeLabel = getScopeConfigForRegionGeneratorAndScope(
        region,
        generator,
        scope
    )?.label;

    const queueProjectsExportColumns =
        getQueueProjectsExportColumnsForRegion(region);

    const handleDownloadQueueProjects = async () => {
        const data = (
            await getQueueProjects({
                variables: { scope, region, scope_view: scopeView }
            })
        ).data;

        if (data === undefined) {
            DownloadQueueProjectsToaster.show({
                message:
                    "Encountered an error while downloading queue projects.",
                intent: Intent.DANGER
            });
            return;
        }

        const queueProjects =
            convertHasuraQueueProjectsToNiraQueueProjects(data);
        const exportObjects = queueProjectsToExportObject(
            queueProjects,
            queueProjectsExportColumns
        );
        await downloadJSONToCSV({
            json: exportObjects,
            filename: `Nira Queue Projects - ${region} - ${scopeLabel}.csv`
        });

        DownloadQueueProjectsToaster.show({
            message: "Downloaded queue projects.",
            intent: Intent.SUCCESS
        });
        segmentTrackDataFreshnessDownloadQueueProjects(
            region,
            scope,
            scopeView
        );
    };

    return (
        <Tooltip
            content={
                <div>
                    Click to download a CSV of which projects <br /> were
                    included in this scope.
                </div>
            }
            position={Position.TOP_RIGHT}
        >
            <Button
                text="Download Projects"
                onClick={handleDownloadQueueProjects}
            />
        </Tooltip>
    );
};

const queueProjectsToExportObject = (
    queueProjects: ReadonlyArray<QueueProject>,
    queueProjectsExportColumns: QueueProjectsExportColumn[]
) => {
    const sortedQueueProjects = [...queueProjects].sort((a, b) => {
        if (a.projectNumber < b.projectNumber) {
            return -1;
        }
        if (a.projectNumber > b.projectNumber) {
            return 1;
        }
        return 0;
    });
    return sortedQueueProjects.map((queueProject) => {
        return queueProjectsExportColumns.reduce((acc, column) => {
            acc[column.getHeader()] = column.renderExportValue(queueProject);
            return acc;
        }, {} as { [header: string]: string });
    });
};

export default DownloadQueueProjectsButton;
