import { QueueProject } from "types/queueProjectsType";
import { RegionType } from "types/regionType";

export interface QueueProjectsExportColumn {
    getHeader: () => string;
    renderExportValue: (queueProject: QueueProject) => string;
}

const PROJECT_NUMBER_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Project Number",
    renderExportValue: (queueProject) => queueProject.projectNumber
};

const SUB_REGION_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Sub Region",
    renderExportValue: (queueProject) => queueProject.subRegion
};
const UTILITY_NAME_COLUMN_HEADER = "Utility Name";

const BUS_ID_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Bus ID",
    renderExportValue: (queueProject) => queueProject.busId ?? ""
};

const FROM_BUS_ID_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "From Bus ID",
    renderExportValue: (queueProject) =>
        queueProject.facilityId?.split("-")[0] ?? ""
};
const TO_BUS_ID_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "To Bus ID",
    renderExportValue: (queueProject) =>
        queueProject.facilityId?.split("-")[1] ?? ""
};

const CLUSTER_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Cluster",
    renderExportValue: (queueProject) => queueProject.cluster ?? ""
};

const POI_DESCRIPTION_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "POI Description",
    renderExportValue: (queueProject) => queueProject.poiDescription
};

const ERIS_CAPACITY_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "ERIS Capacity (MW)",
    renderExportValue: (queueProject) => queueProject.erisCapacityMw.toFixed(2)
};
const PROJECT_SIZE_COLUMN_HEADER = "Project Size (MW)";

const NRIS_CAPACITY_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "NRIS Capacity (MW)",
    renderExportValue: (queueProject) =>
        queueProject.nrisCapacityMw?.toFixed(2) ?? ""
};

const FUEL_TYPE_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Fuel Type",
    renderExportValue: (queueProject) => queueProject.fuelType
};

const STATUS_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Status",
    renderExportValue: (queueProject) => queueProject.status ?? ""
};

const INCLUDED_COLUMN: QueueProjectsExportColumn = {
    getHeader: () => "Included",
    renderExportValue: (queueProject) => queueProject.included.toString()
};

const MISO_QUEUE_PROJECTS_EXPORT_COLUMNS: QueueProjectsExportColumn[] = [
    PROJECT_NUMBER_COLUMN,
    SUB_REGION_COLUMN,
    CLUSTER_COLUMN,
    POI_DESCRIPTION_COLUMN,
    ERIS_CAPACITY_COLUMN,
    NRIS_CAPACITY_COLUMN,
    FUEL_TYPE_COLUMN,
    STATUS_COLUMN,
    INCLUDED_COLUMN
];

const WECC_QUEUE_PROJECTS_EXPORT_COLUMNS: QueueProjectsExportColumn[] = [
    PROJECT_NUMBER_COLUMN,
    { ...SUB_REGION_COLUMN, getHeader: () => UTILITY_NAME_COLUMN_HEADER },
    BUS_ID_COLUMN,
    FROM_BUS_ID_COLUMN,
    TO_BUS_ID_COLUMN,
    POI_DESCRIPTION_COLUMN,
    { ...ERIS_CAPACITY_COLUMN, getHeader: () => PROJECT_SIZE_COLUMN_HEADER },
    FUEL_TYPE_COLUMN,
    STATUS_COLUMN,
    INCLUDED_COLUMN
];

const regionToQueueProjectsExportColumns: {
    [key in RegionType]?: QueueProjectsExportColumn[];
} = {
    MISO: MISO_QUEUE_PROJECTS_EXPORT_COLUMNS,
    WECC: WECC_QUEUE_PROJECTS_EXPORT_COLUMNS
};

export const getQueueProjectsExportColumnsForRegion = (
    region: RegionType
): QueueProjectsExportColumn[] => {
    const columns = regionToQueueProjectsExportColumns[region];
    if (columns === undefined) {
        throw new Error(
            `No queue projects export columns configured for region: ${region}`
        );
    }
    return columns;
};
