import saveAs from "file-saver";
import { json2csvAsync } from "json-2-csv";

interface DownloadJSONToCSVProps {
    json: object[];
    filename: string;
}

export const downloadJSONToCSV = async ({
    json,
    filename
}: DownloadJSONToCSVProps) => {
    const csvString = await json2csvAsync(json, {
        checkSchemaDifferences: true,
        emptyFieldValue: ""
    });

    // Create a Blob and generate a URL for the CSV data
    const blob = new Blob([csvString], { type: "text/csv" });

    saveAs(blob, filename);
};
