import { invert } from "lodash";
import {
    CAISO,
    DUKE,
    ERCOT,
    ISONE,
    MISO,
    NYISO,
    PJM,
    RegionType,
    SOCO,
    SPP,
    TVA,
    WECC
} from "./regionType";
import { User } from "./userType";

export const DOCS_SUBDOMAIN = "https://docs.niraenergy.com";

// These are all specified in GitBook per space
export const PROSPECTING_COMMON_DOCS_SPACE = "prospecting-common-docs";

// We could probably do a for loop, but this is super brittle so we wanted to make it clear what's happening.
// We also did not want to make the Auth0 logic more complicated.
export const PROSPECTING_REGION_SPACE_TO_REGION: { [key: string]: RegionType } =
    {
        // Uncomment these as we support more of them
        "prospecting-miso": MISO,
        "prospecting-spp": SPP,
        "prospecting-pjm": PJM,
        "prospecting-tva": TVA,
        "prospecting-soco": SOCO,
        "prospecting-nyiso": NYISO,
        "prospecting-isone": ISONE,
        "prospecting-wecc": WECC,
        "prospecting-ercot": ERCOT,
        "prospecting-duke": DUKE,
        "prospecting-caiso": CAISO
    };

export const getRegionToSpaceDict: () => {
    [key in RegionType]?: string;
} = () => {
    return invert(PROSPECTING_REGION_SPACE_TO_REGION);
};

const getRequiredToken = (space: string, user: User): string | undefined => {
    if (space === PROSPECTING_COMMON_DOCS_SPACE) {
        // This token will be present as long as the user has a Nira account.
        return user.gitbookTokens?.commonDocsGitbookToken;
    } else if (PROSPECTING_REGION_SPACE_TO_REGION[space] !== undefined) {
        // Region specific tokens will only be present if they have a subscription to the region.
        return user.gitbookTokens?.regionGitbookTokens[
            PROSPECTING_REGION_SPACE_TO_REGION[space]
        ];
    }
};

// This function returns the URL or will return undefined if
// the space is not supported yet or the user doesn't have access to the space.
export const getRedirectUrl = (
    space: string,
    location: string | null,
    user: User
): string | undefined => {
    const maybeToken = getRequiredToken(space, user);
    if (maybeToken) {
        return `${DOCS_SUBDOMAIN}/${space}/${
            location || ""
        }?jwt_token=${maybeToken}`;
    }
};
