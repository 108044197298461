import { RegionType } from "./regionType";

export const SOLAR_TYPE = "solar";
export const WIND_TYPE = "wind";
export const STORAGE_TYPE = "storage";
export const LOAD_TYPE = "load";

export const GENERATOR_TYPES = [
    SOLAR_TYPE,
    WIND_TYPE,
    STORAGE_TYPE,
    LOAD_TYPE
] as const;
export type GeneratorType = (typeof GENERATOR_TYPES)[number];

export const isKnownGeneratorType = (type: string): type is GeneratorType => {
    return GENERATOR_TYPES.includes(type as GeneratorType);
};

export const convertToGeneratorType = (string: string): GeneratorType => {
    if (isKnownGeneratorType(string)) {
        return string;
    }

    throw new Error("Unexpected generator type");
};

const REGION_TO_GENERATOR_TYPE_TO_OVERRIDDEN_LABEL: {
    [key in RegionType]?: { [key in GeneratorType]?: string };
} = {
    WECC: {
        solar: "Solar / Wind",
        storage: "Storage / Hybrid"
    },
    MISO: {
        storage: "Standalone Storage"
    },
    TVA: { solar: "Solar / Wind" },
    SOCO: { solar: "Solar / Wind" },
    DUKE: { storage: "Storage" },
    PJM: { storage: "Standalone Storage" },
    CAISO: { storage: "Storage" }
};

export const GENERATOR_TYPE_TO_DEFAULT_LABEL: {
    [key in GeneratorType]: string;
} = {
    solar: "Solar",
    wind: "Wind",
    storage: "Storage / Solar Hybrid",
    load: "Large Load"
};

export const getLabelForRegionAndGeneratorType = (
    region: RegionType,
    generatorType: GeneratorType
): string => {
    const maybeOverride =
        REGION_TO_GENERATOR_TYPE_TO_OVERRIDDEN_LABEL[region]?.[generatorType];
    return maybeOverride
        ? maybeOverride
        : GENERATOR_TYPE_TO_DEFAULT_LABEL[generatorType];
};
