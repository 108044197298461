import {
    Button,
    Classes,
    Section,
    SectionCard,
    SectionProps
} from "@blueprintjs/core";
import { useStageConfig } from "in_queue/contexts/ClusterContext";
import { useAllProjectsInCluster } from "in_queue/contexts/ProjectDataContext";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import {
    CostAssumptionType,
    MisoPercentageReduction,
    ScenarioAssumptionsCSV,
    ScenarioAssumptionsCSVFields
} from "in_queue/types/scenarioType";
import { useCallback } from "react";
import { downloadJSONToCSV } from "utils/csv";
import { ProjectSizePreview } from "./ProjectSizePreview";
import css from "./ScenarioAssumptions.module.scss";

const ScenarioAssumptions: React.FC = () => {
    const scenario = useCurrentScenarioMetadata();
    const { costAssumptions, stage } = scenario;
    const stageConfig = useStageConfig();
    const projects = useAllProjectsInCluster();

    const scenarioAssumptions: Record<string, MisoPercentageReduction> = {};
    Object.entries(scenario.assumptions).forEach(([projectId, assumption]) => {
        if (assumption.eris !== 0 || assumption.nris !== 0) {
            scenarioAssumptions[projectId] = assumption;
        }
    });

    const downloadAssumptions = useCallback(() => {
        const json = Object.entries(
            scenarioAssumptions
        ).map<ScenarioAssumptionsCSV>(([projectId, assumption]) => ({
            project_id: projectId,
            eris_percentage_reduction: assumption.eris,
            nris_percentage_reduction: assumption.nris
        }));

        // If there are no assumptions, output an empty row so that the CSV
        // includes headers.
        const outputJSON =
            json.length > 0
                ? json
                : [
                      {
                          [ScenarioAssumptionsCSVFields.project_id]: "",
                          [ScenarioAssumptionsCSVFields.eris_percentage_reduction]:
                              "",
                          [ScenarioAssumptionsCSVFields.nris_percentage_reduction]:
                              ""
                      }
                  ];

        const displayName = `Nira Export - ${scenario.title} - Scenario Assumptions`;
        downloadJSONToCSV({
            json: outputJSON,
            filename: `${displayName}.csv`
        });
    }, []);

    if (projects === "loading") {
        return <></>;
    }

    const projectSizePreviews = Object.keys(scenarioAssumptions).map(
        (projectId) => {
            const project = projects.find((p) => p.projectId === projectId);

            if (!project) {
                return <></>;
            }

            const percentageReduction = scenarioAssumptions[projectId];
            const { eris: erisReduction, nris: nrisReduction } =
                percentageReduction;
            let reductionInfo = <>excluded</>;
            if (erisReduction !== 100 || nrisReduction !== 100) {
                if (erisReduction === nrisReduction) {
                    reductionInfo = (
                        <>
                            downsized by <strong>{erisReduction}%</strong>{" "}
                            <ProjectSizePreview
                                projectSize={project?.size}
                                percentageReduction={percentageReduction}
                                sizesToShow="both"
                            />
                        </>
                    );
                } else {
                    reductionInfo = (
                        <>
                            downsized by <strong>{erisReduction}% ERIS</strong>{" "}
                            <ProjectSizePreview
                                projectSize={project?.size}
                                percentageReduction={percentageReduction}
                                sizesToShow="eris"
                            />
                            , <strong>{nrisReduction}% NRIS</strong>{" "}
                            <ProjectSizePreview
                                projectSize={project?.size}
                                percentageReduction={percentageReduction}
                                sizesToShow="nris"
                            />
                        </>
                    );
                }
            }

            return (
                <SectionCard
                    padded={false}
                    className={css["scenario-assumptions-card"]}
                >
                    <strong>{projectId}</strong> is {reductionInfo}{" "}
                </SectionCard>
            );
        }
    );

    const numScenarioAssumptions = Object.keys(scenarioAssumptions).length;
    const projectSizesSection = (
        <ScenarioAssumptionsSection
            title={`Project Size Adjustments (${numScenarioAssumptions})`}
            rightElement={
                <Button
                    className={css["scenario-assumptions-export-button"]}
                    icon="export"
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadAssumptions();
                    }}
                    text="Export"
                    minimal
                />
            }
        >
            {numScenarioAssumptions > 0 ? (
                projectSizePreviews
            ) : (
                <SectionCard
                    padded={false}
                    className={`${css["scenario-assumptions-card"]} ${Classes.TEXT_MUTED}`}
                >
                    No adjustments have been configured for this scenario.
                </SectionCard>
            )}
        </ScenarioAssumptionsSection>
    );

    let maybeCostAssumptionsSection = <></>;
    if (
        stage === "PREVIEW" &&
        stageConfig.configurableCostEstimatesForPreview
    ) {
        maybeCostAssumptionsSection = (
            <ScenarioAssumptionsSection title="Cost Assumptions">
                <SectionCard
                    padded={false}
                    className={css["scenario-assumptions-card"]}
                >
                    {costAssumptions.type ===
                    CostAssumptionType.CONSERVATIVE ? (
                        <>
                            This scenario uses conservative cost estimates. We
                            assume a new single-circuit line build for every
                            constraint.
                        </>
                    ) : (
                        <>
                            <p>This scenario uses custom cost estimates.</p>
                            <p>
                                <b>Reconductor</b> assumed for constraints
                                between 100% and{" "}
                                {costAssumptions.reconductorLoadingLimit}%
                                loading.
                            </p>
                            <p>
                                <b>Rebuild</b> assumed for constraints between{" "}
                                {costAssumptions.reconductorLoadingLimit}% and{" "}
                                {costAssumptions.rebuildLoadingLimit}% loading.
                            </p>
                            <p>
                                <b>New single-circuit line build</b> assumed for
                                constraints above{" "}
                                {costAssumptions.rebuildLoadingLimit}% loading.
                            </p>
                        </>
                    )}
                </SectionCard>
            </ScenarioAssumptionsSection>
        );
    }

    const maybeInputDataUsedSection = scenario.inputDataUsed && (
        <ScenarioAssumptionsSection title="Input Data">
            <SectionCard
                padded={false}
                className={`${css["scenario-assumptions-card"]}`}
            >
                <p>
                    Input data:{" "}
                    <strong>{scenario.inputDataUsed?.concise}</strong>
                </p>
                <p style={{ whiteSpace: "pre-wrap" }}>
                    Input files: <em>{scenario.inputDataUsed?.description}</em>
                </p>
            </SectionCard>
        </ScenarioAssumptionsSection>
    );

    return (
        <>
            {projectSizesSection}
            {maybeCostAssumptionsSection}
            {maybeInputDataUsedSection}
        </>
    );
};

interface ScenarioAssumptionsSectionProps extends SectionProps {
    title: string;
}
const ScenarioAssumptionsSection: React.FC<ScenarioAssumptionsSectionProps> = ({
    title,
    children,
    ...props
}) => {
    return (
        <Section
            collapsible={true}
            collapseProps={{ defaultIsOpen: false }}
            compact={true}
            title={title}
            className={css["scenario-assumptions"]}
            {...props}
        >
            <div className={css["scenario-assumptions-body"]}>{children}</div>
        </Section>
    );
};

export default ScenarioAssumptions;
